import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <section>
      <article className={'article'}>
        <div className="article__content">
          <h1>Helaas</h1>
          <p>Deze pagina kon niet worden gevonden.</p>
        </div>
      </article>
    </section>
  </Layout>
);

export default NotFoundPage;
